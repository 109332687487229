import React from "react"
import { Link } from "gatsby"
import "./marker.css"
import { StaticImage } from "gatsby-plugin-image"
import DefaultBackgroundImage from "../images/sell-home.jpg"
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Learn = ({ image, text, backgroundImage }) => {
  return (
    <div
      style={{
        backgroundImage: `url("${backgroundImage || DefaultBackgroundImage}")`,
      }}
      className="cha-learns__container"
    >
      <div className="containers">
        {image ? (
          <LazyLoadImage 
            src={image} 
            alt="Sell Your Home" 
            />
        ) : (
          <LazyLoadImage
            className="cha-learns__container__inner"
            alt="Sell you home"
            src="../images/sell-home-inner.jpg"
            threshold={250}
          />
        )}

        <div className="cha-learns__container__sell">
          <h1 className="h1-header desktop">Sell&nbsp;<br/>your home</h1>
          <h1 className="h1-header mobile">Sell your home</h1>
          {text ? (
            <p className="body1">{text}</p>
          ) : (
            <p className="body1">
              Christie’s International Real Estate connects savvy buyers and
              sellers of the world’s most distinctive and desirable properties.
              Christie’s caters to a global clientele courtesy of a vast,
              widespread network of more than 138 top brokerages based in 46
              countries.
            </p>
          )}
          <Link to="/sell-your-home" className="cha-learns__container__sell__link button-dark-gray">
            LEARN MORE
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Learn
