import React from "react"
import { Link } from "gatsby"
import parse from 'html-react-parser'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Communities = ({ communities, communitiesSectionTitle, communitiesSectionSubHeader }) => {
  if (!communities) return <div>Loading</div>

  return (
    <div className="cha-c__communities">
      <div className="cha-c__communities__inner containers">
        <h1 className="cha-c__communities__inner__title h1-header">{communitiesSectionTitle}</h1>
        <p className="cha-c__communities__inner__sub-title subhead">
          {communitiesSectionSubHeader}
        </p>
        <div className="containers">
          <div className="cha-c__communities__inner__body">
            {/* mobile */}
            <div className="cha-c__communities__inner__body__right mobile">
              {communities.map((c, index) => {
                return (
                  <div>
                    <div className="cha-c__communities__inner__body__right__image">
                      <LazyLoadImage
                        src={process.env.MEDIA_CACHE_URL_PREPEND.replace('WIDTH', 620).replace('HEIGHT', 620 / 1.78) + c.image.url}
                        alt="community"
                        threshold={250}
                      />
                    </div>
                    <div className="cha-c__communities__inner__body__right__content">
                      <p className="cha-c__communities__inner__body__right__content__title">
                        {c.name}
                      </p>
                      <p className="cha-c__communities__inner__body__right__content__text">
                        {parse(c.description.slice(0, 300) + "...")}
                      </p>
                      <Link
                        className="cha-c__communities__inner__body__right__content__link"
                        to={`/community/${c.path}`}
                      >
                        VIEW MORE
                      </Link>
                    </div>
                  </div>
                )
              })}
            </div>
            {/* left */}
            <div className="cha-c__communities__inner__body__left">
              {communities.map((c, index) => {
                if (index % 2) {
                  return (
                    <div className="cha-c__communities__inner__body__left__content">
                      <h2 className="cha-c__communities__inner__body__left__content__title h2-header">
                        {c.name}
                      </h2>
                      <p className="cha-c__communities__inner__body__left__content__text body1">
                        {parse(c.description.slice(0, 300) + "...")}
                      </p>
                      <Link
                        className="cha-c__communities__inner__body__left__content__link button-dark-gray"
                        to={`/community/${c.path}`}
                      >
                        VIEW MORE
                      </Link>
                    </div>
                  )
                } else {
                  return (
                    <div className="cha-c__communities__inner__body__left__image">
                      <LazyLoadImage
                        threshold={250}
                        src={process.env.MEDIA_CACHE_URL_PREPEND.replace('WIDTH', 620).replace('HEIGHT', 620 / 1.78) + c.image.url} alt="community" />
                    </div>
                  )
                }
              })}
            </div>

            {/* right */}
            <div className="cha-c__communities__inner__body__right">
              {communities.map((c, index) => {
                if (index % 2) {
                  return (
                    <div className="cha-c__communities__inner__body__right__image">
                      <LazyLoadImage
                        src={process.env.MEDIA_CACHE_URL_PREPEND.replace('WIDTH', 620).replace('HEIGHT', 620 / 1.78) + c.image.url}
                        alt="community"
                        threshold={250}
                      />
                    </div>
                  )
                } else {
                  return (
                    <div className="cha-c__communities__inner__body__right__content">
                      <h2 className="cha-c__communities__inner__body__right__content__title h2-header">
                        {c.name}
                      </h2>
                      <p className="cha-c__communities__inner__body__right__content__text body1">
                        {parse(c.description.slice(0, 300) + "...")}
                      </p>
                      <Link
                        className="cha-c__communities__inner__body__right__content__link button-dark-gray"
                        to={`/community/${c.path}`}
                      >
                        VIEW MORE
                      </Link>
                    </div>
                  )
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Communities
