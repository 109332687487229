import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const HomePropertyHeaderDetail = ({ property }) => {
  return (
    <div className="cha-hp-container__header">
      <div className="cha-hp-container__header__inner">
        <div className="cha-hp-container__header__inner__second-text">
          ${new Intl.NumberFormat().format(property.ListPrice)}
        </div>
        <div className="cha-hp-container__header__inner__box">
          <div className="cha-hp-container__header__inner__first-text info">
            {property.UnparsedAddress}
          </div>
          <div className="cha-hp-container__header__inner__third-text">
            <StaticImage src="../images/bed-icon.png" alt="beds" />
            <span>{property.BedroomsTotal || 0}</span>
            <StaticImage src="../images/shower-icon.png" alt="beds" />
            <span>{property.BathroomsTotalInteger || 0}</span>
            <StaticImage src="../images/rule-icon.png" alt="beds" />
            <span>{new Intl.NumberFormat().format(property.LivingArea) || 0}</span>
          </div>
        </div>
        <Link to={property.path}>
          <button
            type="button"
            className="cha-hp-container__header__inner__button"
          >
            VIEW DETAILS
          </button>
        </Link>
      </div>
    </div>
  )
}

export default HomePropertyHeaderDetail
