import React, { useCallback, useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import "./inputSlider.css"

const InputSlider = ({ min, max, style, minInputChange, maxInputChange, step, valueOutputFormat, existingMin, existingMax }) => {

  const [minVal, setMinVal] = useState(existingMin ? existingMin : min)
  const [maxVal, setMaxVal] = useState(existingMax ? existingMax : max)
  const minValRef = useRef(existingMin ? existingMin : min)
  const maxValRef = useRef(existingMax ? existingMax : max)
  const range = useRef(null)

  // Convert to percentage
  const getPercent = useCallback(
    value => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  )

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal)
    const maxPercent = getPercent(maxValRef.current)

    if (range.current) {
      range.current.style.left = `${minPercent}%`
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [minVal, getPercent])

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current)
    const maxPercent = getPercent(maxVal)

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [maxVal, getPercent])

  return (
    <div className="input-slider-container" style={style}>
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        step={step}
        onChange={event => {
          const value = Math.min(Number(event.target.value), maxVal - 1)
          setMinVal(value)
          minValRef.current = value
          // minInputChange(minValRef.current)
          minInputChange(event.target.value)
        }}
        className="input-slider-thumb input-slider-thumb--left"
        style={{ zIndex: minVal > max - 100 && "5" }}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        step={step}
        onChange={event => {
          const value = Math.max(Number(event.target.value), minVal + 1)
          setMaxVal(value)
          maxValRef.current = value
          // maxInputChange(maxValRef.current)
          maxInputChange(event.target.value)
        }}
        className="input-slider-thumb input-slider-thumb--right"
      />

      <div className="input-slider">
        <div className="input-slider__track" />
        <div ref={range} className="input-slider__range" />
        <div className="input-slider__left-value">
          {valueOutputFormat ? (valueOutputFormat(minVal)): (minVal)}
        </div>
        <div className="input-slider__right-value">
          {valueOutputFormat ? (valueOutputFormat(maxVal)): (maxVal)}
        </div>
      </div>
    </div>
  )
}

InputSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
}

export default InputSlider
