/* eslint-disable jsx-a11y/no-onchange */

import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import { Carousel } from "react-responsive-carousel"
import { StaticImage } from "gatsby-plugin-image"
import { compact, uniq } from "lodash"
import parse from "html-react-parser"
import { LazyLoadImage } from "react-lazy-load-image-component"

import "react-lazy-load-image-component/src/effects/blur.css"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Communities from "../components/communities"
import HomePropertyHeaderDetail from "../components/home-property-header-detail"
import Learn from "../components/learn"
import Market from "../components/market"
import InputSlider from "../components/inputSlider/inputSlider"
import StandardGallery from "../components/standard-gallery"

import "react-toggle/style.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import "../components/layout.css"
import "../sass/index.scss"
import "../sass/components/carousel-slider.scss"

const IndexPage = ({ data, location }) => {
  const emptyQuery = {}

  const emptyFilter = {
    ListingId: [],
    ListOfficeMlsId: [],
    MLSAreaMajor: [],
    MLSAreaMinor: [],
    ListPrice: {
      min: null,
      max: null,
    },
    BedroomsTotal: {
      min: null,
      max: null,
    },
    BathroomsTotalInteger: {
      min: null,
      max: null,
    },
    LivingArea: {
      min: null,
      max: null,
    },
    LotSizeAcres: {
      min: null,
      max: null,
    },
    SubdivisionName: [],
    PropertyType: [],
    ArchitecturalStyle: [],
    ParkingFeatures: [],
    PropertyCondition: [],
    StandardStatus: [],
    VirtualTourURLUnbranded: [],
  }

  const allPosts = data.allListingResidential.nodes
  const currentListing = data.allListingResidential.nodes.filter(
    p => p.ListOfficeMlsId === process.env.OFFICE_MLS_ID
  )
  const heroProperties = data.allHeropage.nodes.filter(
    property => property.PrefferedPhoto
  )

  const [, setPageNumber] = useState(0)
  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
    filter: emptyFilter,
  })

  const [dynamicWidth, setDynamicWidth] = useState("location")
  const [dynamicArea, setdynamicArea] = useState("area")

  const allLocations = {}

  allPosts.forEach(element => {
    if (allLocations.hasOwnProperty(element["MLSAreaMajor"])) {
      allLocations[element["MLSAreaMajor"]].push(element["MLSAreaMinor"])
    } else {
      allLocations[element["MLSAreaMajor"]] = [element["MLSAreaMinor"]]
    }
  })

  Object.getOwnPropertyNames(allLocations).forEach(location => {
    allLocations[location] = uniq(compact(allLocations[location]))
  })
  const [allAreas, setAllAreas] = useState([])
  const [selectedArea, setArea] = useState("")

  const buildFilter = filter => {
    let query = {}
    for (let keys in filter) {
      if (
        filter[keys].constructor === Object ||
        (filter[keys].constructor === Array && filter[keys].length > 0)
      ) {
        query[keys] = filter[keys]
      }
    }
    return query
  }

  const filterData = (data, query) => {
    const keysWithMinMax = [
      "ListPrice",
      "BedroomsTotal",
      "BathroomsTotalInteger",
      "LivingArea",
      "LotSizeAcres",
    ]
    const filteredData = data.filter(item => {
      for (let key in query) {
        switch (key) {
          case "ArchitecturalStyle":
          case "PropertyCondition":
            if (!item[key]) {
              return false
            } else if (item[key].length === 1) {
              if (item[key][0] !== query[key][0]) {
                return false
              } else {
                break
              }
            } else if (item[key].length > 1) {
              for (let i = 0; i < item[key].length; i++) {
                if (item[key][i] !== query[key][0]) {
                  return false
                } else {
                  break
                }
              }
            }
            break
          case "ListingId":
            if (!item[key]) {
              return false
            } else if (query[key].length > 0) {
              for (let i = 0; i < query[key][0].length; i++) {
                if (query[key][0][i] !== item[key].toString()[i]) {
                  return false
                }
              }
              break
            }
            break
          case "VirtualTourURLUnbranded":
            if (!item[key]) {
              return false
            } else {
              break
            }
          default:
            if (!item[key] && item[key] !== 0) {
              return false
            } else if (keysWithMinMax.includes(key)) {
              if (query[key]["min"] !== null && item[key] < query[key]["min"]) {
                return false
              }
              if (query[key]["max"] !== null && item[key] > query[key]["max"]) {
                return false
              }
            } else if (!query[key].includes(item[key])) {
              return false
            }
        }
      }

      return true
    })
    return filteredData
  }

  const handleLocationInputChange = event => {
    if (event.target.value === "Snowmass Village") {
      setDynamicWidth("snow-village")
    } else if (event.target.value === "Woody Creek") {
      setDynamicWidth("wood-creek")
    } else if (event.target.value === "Old Snowmass") {
      setDynamicWidth("old-snow")
    } else if (event.target.value === "Missouri Heights") {
      setDynamicWidth("missouri")
    } else if (event.target.value === "Carbondale") {
      setDynamicWidth("carbondale")
    } else if (event.target.value === "Redstone/Crystal Valley") {
      setDynamicWidth("redstone")
    } else if (event.target.value === "Glenwood") {
      setDynamicWidth("glenwood")
    } else if (event.target.value === "Brush Creek Village") {
      setDynamicWidth("brush-village")
    } else {
      setDynamicWidth("location")
    }

    if (event.target.value && event.target.value !== "") {
      filter.MLSAreaMajor.length = 0
      if (event.target.value !== "all") {
        filter.MLSAreaMajor.push(event.target.value)
      }

      filter.MLSAreaMinor.length = 0
      if (event.target.value === "all") {
        setAllAreas([])
      } else {
        setAllAreas(allLocations[event.target.value])
      }
      setArea("")
    } else {
      filter.MLSAreaMajor.length = 0
    }

    updateState()
  }

  const handleAreaInputChange = event => {
    if (event.target.value === "") {
      setdynamicArea("area")
    } else {
      setdynamicArea("")
    }

    if (event.target.value && event.target.value !== "") {
      filter.MLSAreaMinor.length = 0
      setArea(event.target.value)
      filter.MLSAreaMinor.push(event.target.value)
    } else {
      filter.MLSAreaMinor.length = 0
    }

    updateState()
  }

  const handlePriceMinInputChange = minValue => {
    if (minValue && minValue !== minPriceSliderValue.toString()) {
      filter.ListPrice.min = null
      filter.ListPrice.min = minValue
    } else {
      filter.ListPrice.min = null
    }

    updateState()
  }

  const handlePriceMaxInputChange = maxValue => {
    if (maxValue && maxValue !== maxPriceSliderValue.toString()) {
      filter.ListPrice.max = null
      filter.ListPrice.max = maxValue
    } else {
      filter.ListPrice.max = null
    }

    updateState()
  }

  const handleBedsInputChange = event => {
    if (event.target.value && event.target.value !== "") {
      filter.BedroomsTotal.min = null
      filter.BedroomsTotal.min = event.target.value
    } else {
      filter.BedroomsTotal.min = null
    }

    updateState()
  }

  const handleBathsInputChange = event => {
    if (event.target.value && event.target.value !== "") {
      filter.BathroomsTotalInteger.min = null
      filter.BathroomsTotalInteger.min = event.target.value
    } else {
      filter.BathroomsTotalInteger.min = null
    }

    updateState()
  }

  const updateState = () => {
    const query = buildFilter(filter)
    const filteredData = filterData(allPosts, query)

    setState({
      query,
      filteredData,
      filter,
    })
    setPageNumber(0)
  }

  const { filter } = state

  const minPriceSliderValue = 0
  const maxPriceSliderValue = 100000000

  return (
    <Layout>
      <Seo location={location} />
      <Carousel
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={100}
        showArrows={true}
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        autoPlay={true}
        stopOnHover={false}
        interval={100000}
        transitionTime={1000}
        infiniteLoop={true}
      >
        {heroProperties.map((p, index) => {
          return (
            <>
              <img
                className="cha-h__carousel__img"
                src={p.PrefferedPhoto.replace("WIDTH", 1800).replace(
                  "HEIGHT",
                  1800 / 1.5
                )}
                visibleByDefault={index === 0}
              />

              <div className="cha-h__property-header-detail-container">
                <div className="lower-slider-gradient"></div>
                <HomePropertyHeaderDetail property={p} />
              </div>
            </>
          )
        })}
      </Carousel>
      <div className="home-search-container">
        <div id="filter-options">
          <div
            id="left-outside-filter-option"
            className="outside-filter-option"
          >
            <div className="select-border-container">
              <select
                name="location"
                id="location"
                className={dynamicWidth}
                onChange={handleLocationInputChange}
              >
                <option selected disabled value="">
                  LOCATION
                </option>
                <option value="">All</option>
                {data.allLocation.nodes.map(i => {
                  return (
                    <option value={i.locationItem.value}>
                      {i.locationItem.text}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className="select-container">
              <select
                name="area"
                id="area"
                className={dynamicArea}
                onChange={handleAreaInputChange}
              >
                <option selected disabled value="">
                  AREA
                </option>
                <option value="">All</option>
                {allAreas.map(area => {
                  return <option value={area}>{area}</option>
                })}
              </select>
            </div>
          </div>
          <div id="middle-filter-option">
            <span>PRICE</span>
            <InputSlider
              style={{
                marginRight: `3vw`,
              }}
              min={minPriceSliderValue}
              max={maxPriceSliderValue}
              minInputChange={handlePriceMinInputChange}
              maxInputChange={handlePriceMaxInputChange}
              step={process.env.BUY_PROPERTY_PRICE_SLIDER_INCREMENT}
              valueOutputFormat={value =>
                "$" + new Intl.NumberFormat().format(value)
              }
            />
          </div>
          <div
            id="right-outside-filter-option"
            className="outside-filter-option"
          >
            <div className="select-container">
              <select
                name="beds"
                id="beds"
                className="beds"
                onChange={handleBedsInputChange}
              >
                <option selected disabled value="">
                  BEDS
                </option>
                <option value="">0+</option>
                <option value="1">1+</option>
                <option value="2">2+</option>
                <option value="3">3+</option>
                <option value="4">4+</option>
                <option value="5">5+</option>
                <option value="6">6+</option>
                <option value="7">7+</option>
                <option value="8">8+</option>
              </select>
            </div>
            <div className="select-borderleft-container">
              <select name="baths" id="baths" onChange={handleBathsInputChange}>
                <option selected disabled value="">
                  BATHS
                </option>
                <option value="">0+</option>
                <option value="1">1+</option>
                <option value="2">2+</option>
                <option value="3">3+</option>
                <option value="4">4+</option>
                <option value="5">5+</option>
                <option value="6">6+</option>
                <option value="7">7+</option>
                <option value="8">8+</option>
              </select>
            </div>
          </div>
          <div className="go-container">
            <Link
              className="go-container-inner-go"
              to="/places"
              state={{ homeFilter: filter, selectedArea: selectedArea }}
            >
              <span>GO</span>
              <StaticImage src="../images/go-icon.png" alt="Go to places" />
            </Link>
          </div>
        </div>
      </div>
      <div className="standard-gallery-container">
        <h1 className="h1-header">
          {data.homeContent.featuredPropertiesTitle}
        </h1>
        <StandardGallery posts={currentListing} />
      </div>

      <Communities
        communitiesSectionTitle={data.homeContent.communitiesSectionTitle}
        communitiesSectionSubHeader={
          data.homeContent.communitiesSectionSubHeader
        }
        communities={data.allCommunities.nodes}
      />
      <Learn
        image={data.homeContent.sellYourHomeImage.url}
        text={parse(data.homeContent.sellYourHomeContent)}
        backgroundImage={data.homeContent.sellYourHomeBackgroundImage.url}
      />
      <Market
        marketReportContent={parse(data.homeContent.marketReportContent)}
        marketReportHeader={data.homeContent.marketReportHeader}
        marketReportSubheader={data.homeContent.marketReportSubheader}
        marketReportLearnMoreHref={data.homeContent.marketreportlink.href}
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query MyQueryHome {
    allListingResidential(filter: { StandardStatus: { ne: "Closed" } }) {
      nodes {
        ListPrice
        BedroomsTotal
        BathroomsTotalInteger
        UnparsedAddress
        PrefferedPhoto
        ListOfficeMlsId
        ListingId
        MLSAreaMajor
        MLSAreaMinor
        parsedAddress
        SubdivisionName
        LivingArea
        ArchitecturalStyle
        LotSizeAcres
        PropertyCondition
        StandardStatus
        path
      }
    }

    allCommunities(sort: { fields: homepagePosition, order: ASC }) {
      nodes {
        id
        name
        description
        image {
          url
        }
        homepagePosition
        gallery {
          media {
            url
          }
        }
        path
      }
    }

    allHeropage(filter: { StandardStatus: { ne: "Closed" } }) {
      nodes {
        ListPrice
        BedroomsTotal
        BathroomsTotalInteger
        UnparsedAddress
        parsedAddress
        PrefferedPhoto
        ListOfficeMlsId
        ListingId
        MLSAreaMajor
        MLSAreaMinor
        SubdivisionName
        LivingArea
        ArchitecturalStyle
        LotSizeAcres
        PropertyCondition
        StandardStatus
        path
      }
    }

    homeContent {
      communitiesSectionSubHeader
      communitiesSectionTitle
      featuredPropertiesTitle
      featuredPropertiesViewAllCTA
      marketReportContent
      marketReportHeader
      marketReportSubheader
      sellYourHomeBackgroundImage {
        url
      }
      sellYourHomeContent
      sellYourHomeImage {
        url
      }
      sellYourHomeSectionTitle
      marketreportlink {
        href
      }
    }

    allLocation(sort: { fields: locationItem___index, order: ASC }) {
      nodes {
        locationItem {
          value
          text
        }
      }
    }
  }
`
